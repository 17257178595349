<template>
    <div class="icon_wrapper" >
        <div class="icon" @mouseenter="mouseEnterLogic" @mouseleave="mouseLeaveLogic" @click="clickLogicMobile">
            <div style="position: relative;" @click="clickAnimation">
                <img class="icon__active" :src="item.active" alt="" :style="{opacity: type !== 'active' || !showActiveAnim && showPlay || !showActiveAnim && showPause ? 0 : 1}" >
                <img class="icon__active" :src="item.play" alt="" :style="{opacity: type !== 'play' || !showActiveAnim && showPlay || !showActiveAnim && showPause  ? 0 : 1}" >
                <img class="icon__active" :src="item.stop" alt="" :style="{opacity: type !== 'stop' || !showActiveAnim && showPlay || !showActiveAnim && showPause  ? 0 : 1}" >
            </div>
            <div v-if="!showActiveAnim">
                <img class="icon__pause_btn" :src="`${$router.options.base}media/main/pause.svg`" alt="pause" v-if="showPause" @click="pausePlay">
                <img class="icon__play_btn" :src="`${$router.options.base}media/main/play.svg`" alt="play" v-if="showPlay" @click="startPlay">
            </div>
        </div>
        <audio
            ref="audio"
            :src="item.audio" class="icon__audio"/>
    </div>
</template>

<script>
export default {
    props: ['item', 'allActive', 'cursorPosition'],
    data() {
        return {
            isHover: false,
            isPlay: false,
            clickCursorPosition: null,
            showPause: false,
            showPlay: false,
            doubleCheckPositionCursor: null,
            showActiveAnim: false,
        }
    },
    computed: {
        type() {
            if(this.allActive) return 'active'
            return this.item.clicked ? 'play' : 'stop'
        },
        isMobile() {
            return window.innerWidth < 769
        }
    },
    methods: {
        mouseLeaveLogic() {
            if(!this.isMobile) {
                this.isHover = false
                // if(this.isActiveAnim) this.isActiveAnim = false
                if(this.showPlay) this.showPlay = false
                if(!this.isPlay) this.$emit('updCount')
            }
            
        },
        mouseEnterLogic() {
            if(!this.isMobile) {
                this.isHover = true
                if(!this.isPlay) this.showPlay = true
            }
        },
        clickLogicMobile() {
            if(this.isMobile) {
                if(!this.isPlay) {
                    this.showPlay = true
                    this.$emit('removePlayIcons')
                }
                // else this.showPlay = true
            }
        },
        async startPlay(e) {
            await this.$emit('onClick')
            if(this.item.clicked) {
                console.log('clickStart')
                this.showPlay = false
                // this.isActiveAnim = false

                this.$refs.audio.play()

                this.isPlay = true
                this.clickCursorPosition = {
                    x: e.clientX,
                    y: e.clientY,
                }

                this.doubleCheckPositionCursor = setTimeout(() => {
                    console.log(this.clickCursorPosition.x, this.cursorPosition.x)
                    if(this.isMobile || this.clickCursorPosition.x !== this.cursorPosition.x || this.clickCursorPosition.y !== this.cursorPosition.y) {
                        this.showPause = true;
                        setTimeout(() => {
                            this.showPause = false
                        }, 4000)
                    }
                }, 2500)
            }
        },
        async pausePlay() {
            await this.$emit('onClick')

            if(!this.item.clicked) {
                this.showPause = false
                this.isPlay = false
                console.log('clickPause')
                this.$refs.audio.pause()

                this.showActiveAnim = true
            }
        },
        checkPause() {
            if(this.isPlay) {
                clearTimeout(this.doubleCheckPositionCursor)
                this.showPause = true
            }
        },
        clickAnimation() {
            if(!this.isPlay) {
                if(this.showActiveAnim) this.showActiveAnim = false
                this.showPlay = true
            }
            else this.showPause = true
        }
    }
}
</script>

<style scoped lang="scss">
.icon {
    width: 224px;
    cursor: pointer;
    position: relative;
    &__active {
        transition: opacity 0.4s ease-in-out;
        position: absolute; 
        left: 50%; 
        top: 50%; 
        transform: translate(-50%, -50%);
    }
    &__play_btn, &__pause_btn {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: opacity 0.4s ease-in-out;
    }
    img {
        max-width: 100%;
    }
    &__audio {
        visibility: hidden;
    }
    &_wrapper {
        width: calc(50% - 7.2%);
        height: 250px;
        margin-bottom: 14.7%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        &:first-of-type, &:nth-of-type(2) {
            margin-top: 14.7%;
        } 
        &:nth-of-type(2n) {
            justify-content: flex-start;
        }
    }
    @media(max-width: 768px) {
        width: 173px;
        &_wrapper {
            width: 100%;
            height: 174px;
            margin-bottom: 119px;
            justify-content: center;
            &:first-of-type {
                margin-top: 135px;
            }
            &:last-of-type {
                margin-bottom: 147px;
            }

            &:nth-of-type(2n) {
                justify-content: center;
                margin-top: 0;
            }
        }
        &__play_btn, &__pause_btn {
            width: 50px;
        }
    }
}
</style>

<template>
    <div class="main_screen content-grid">
        <div class="main_screen__video"><video autoplay="autoplay" muted :src="`${$router.options.base}media/main/main.mp4`"></video></div>
        <h1 class="main_screen__title">{{$t("main.title")}}</h1>
        <MainButton class="main_screen__btn" type="main" :text="$t('header.projects')" @click.native="$router.push({name: 'project'})"/>
    </div>
</template>

<script>
import MainButton from '@/components/elements/Button.vue'

export default {
    components: {
        MainButton
    }
}
</script>

<style lang="scss" scoped>
.main_screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
    width: 100%;
    overflow: hidden;
    &__video {
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        video {
            width: 100%;
        }
       
    }
    &__title {
        font-family: 'Oswald', sans-serif;
        font-size: 120px;
        font-weight: 500;
        line-height: 1.12;
        letter-spacing: 2.46px;
        text-align: center;
        color: #fff;
        margin-bottom: 65px;
    }

}
@media(max-width: 768px) {
    .main_screen {
        height: 524px;
        &__video {
            min-height: 100%;
            min-width: 100%;
        }
        &__title {
            font-size: 51px;
            font-weight: 500;
            letter-spacing: 1.05px;
            text-align: center;
            line-height: 1;
            color: #fff;
            margin-bottom: 45px;
        }

    }
}
@media(max-width: 525px) {
    .main_screen {
        height: 524px;
        &__video {
            video {
                height: 524px;
                width: 524px;
            }
        }
    }
}
</style>